import React, { useContext } from "react"
import { makeStyles } from "@material-ui/styles"
import { ScreenContext } from "../../context/screenContext"
import { AppContext } from "../../context/appContext"
import CKDStageDialog from "../ckdStageDialog"

const useStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    maxWidth: "1800px",
    paddingLeft: "auto",
    paddingRight: "auto",
    paddingBottom: "50px",
    paddingTop: "20px",
  },
  containerMobile: { paddingBottom: "100px", paddingLeft: "10px", paddingRight: "20px" },
  stageSelectorMobile: { marginBottom: "15px"}
}))

const TopicsMain = ({ children }) => {
  const screen = useContext(ScreenContext)
  const { CKDStage } = useContext(AppContext)
  const classes = useStyles()

  return (
    <div
      className={screen.tablet ? classes.containerMobile : classes.container}
    >
      {!CKDStage ? (
        <CKDStageDialog open={true} setOpen={() => null} />
      ) : (
        <>{children}</>
      )}
    </div>
  )
}

export default TopicsMain
