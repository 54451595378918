import React, { useContext } from "react"
import { Grid, Breadcrumbs, Typography, Link } from "@material-ui/core"
import { navigate } from "gatsby"
import TopicCard from "./topicCard"
import { AppContext } from "../../context/appContext"

const CategoryTopics = ({ category, categoryTopics }) => {
  const { CKDStage } = useContext(AppContext)

  const getStageFilteredTopics = () => {
    return categoryTopics.filter(topic => topic.stages.includes(CKDStage))
  }

  const getSlideCount = topic => {
    return topic.slides.length
  }

  return (
    <>
      <Breadcrumbs style={{ marginBottom: "20px" }}>
        <Link color="inherit" href="#" onClick={() => navigate(`/knowledge`)}>
          Knowledge
        </Link>
        <Typography color="textPrimary">{category.categoryHeader}</Typography>
      </Breadcrumbs>
      <Grid container spacing={4}>
        {getStageFilteredTopics().map(topic => {
          return (
            <Grid key={topic.slug} item xs={12} sm={6} lg={4}>
              <TopicCard
                category={category}
                topic={topic}
                slideCount={getSlideCount(topic)}
              />
            </Grid>
          )
        })}
      </Grid>
    </>
  )
}

export default CategoryTopics
