import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import TopicsMain from "../components/topics/topicsMain"
import CategoryTopics from "../components/topics/categoryTopics"

export const query = graphql`
  query categoryTopics($categorySlug: String) {
    allStrapiTopic(filter: { category: { slug: { eq: $categorySlug } } }) {
      nodes {
        slug
        stages {
          stageCKD
        }
        title
        subtitle
        coverImage {
          publicURL
          extension
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        slides {
          id
        }
      }
    }
    strapiCategory(slug: { eq: $categorySlug }) {
      categoryHeader
      slug
    }
  }
`

const CategoryPage = ({
  data: {
    allStrapiTopic: { nodes: topics },
    strapiCategory: category,
  },
}) => {

  return (
    <Layout activePage="knowledge">
      <SEO title="Topics" />
      
      <TopicsMain>
        <CategoryTopics
          category={category}
          categoryTopics={topics.map(topic => {
            return {
              ...topic,
              stages: topic.stages.map(s => s.stageCKD),
            }
          })}
        />
      </TopicsMain>
    </Layout>
  )
}

export default CategoryPage
