import React from "react"
import Image from "gatsby-image"
import { navigate } from "gatsby"
import {
  CardActionArea,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  Typography,
} from "@material-ui/core"
import DynamicFeedIcon from "@material-ui/icons/DynamicFeed"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles({
  cardContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    marginRight: "10px",
  },
})

const TopicCard = ({ category, topic, slideCount, small }) => {
  const classes = useStyles()

  return (
    <CardActionArea
      style={{ height: "100%" }}
      onClick={() => navigate(`/knowledge/${category.slug}/${topic.slug}`)}
    >
      <Card key={topic.slug} style={{ height: "100%" }}>
        <CardHeader
          title={topic.title}
          subheader={topic.subtitle}
          titleTypographyProps={{
            variant: small ? "body1" : "h6",
            noWrap: true,
          }}
          subheaderTypographyProps={{ variant: "subtitle2", noWrap: true }}
        />
        <CardMedia>
          <Image
            fluid={topic.coverImage.childImageSharp.fluid}
            style={{ height: "160px" }}
          />
        </CardMedia>
        {!small ? (
          <CardContent className={classes.cardContent}>
            <DynamicFeedIcon className={classes.icon} />
            <Typography variant="body2">
              <strong>{`${slideCount} Slides`}</strong>
            </Typography>
          </CardContent>
        ) : null}
      </Card>
    </CardActionArea>
  )
}

export default TopicCard
